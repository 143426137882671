import styled, { css } from "styled-components"

export const Wrapper = styled.div`
  h1 {
    margin-bottom: 0;
    padding: 1rem;
    color: #7b6244;
    background-color: #f7f2ea;
  }
  img {
    margin-top: 0;
    width: 100%;
    object-fit: cover;
    max-height: 600px;
  }
  p {
    color: #111a2d;
    line-height: 31px;
    text-align: justify;
  }
  ${props =>
    props.color === "brown" &&
    css`
      h1 {
      }
    `}
  ${props =>
    props.color === "teal" &&
    css`
      h1 {
        background-color: #eaf2f3;
        color: #3f4a59;
      }
    `}
  @media only screen and (min-width: 768px) {
    h1 {
      width: max-content;
    }
  }
`

export const DecorWrapper = styled.div`
  img {
    display: none;
    border-radius: 50%;
  }
  @media only screen and (min-width: 1024px) {
    display: flex;
    justify-content: center;
    margin: 8rem 0;
    img {
      display: block;
      width: 180px;
    }
  }
`
