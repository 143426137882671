import React from "react"
import Layout from "../components/layout"
import { graphql } from "gatsby"
import { DecorWrapper, Wrapper } from "./styles"
import Footer from "../components/Footer"
import Header from "../components/Header"
import SpaunaLogo from "../assets/logos/spauna-logo.svg"
import SEO from "../components/seo"

export default function BlogPost({ data, pageContext }) {
  const post = data.allWpPost.nodes[0]
  const seo = pageContext.seo

  /* color={post.categories.nodes.map(col => {
    if (col.slug === "saun") {
      return "brown"
    } else if (col.slug === "spa") {
      return "teal"
    }
  })} */

  return (
    <>
      <Header />
      <Layout>
        <SEO title={post.title} description={seo?.metaDesc} />
        <Wrapper>
          <h1>{post.title}</h1>
          {post.featuredImage && (
            <img src={post.featuredImage?.node.sourceUrl} />
          )}
          <div
            dangerouslySetInnerHTML={{ __html: post.content }}
            style={{
              marginTop: "2rem",
            }}
          />
          <DecorWrapper>
            <img src={SpaunaLogo} />
          </DecorWrapper>
        </Wrapper>
      </Layout>
      <Footer />
    </>
  )
}
export const query = graphql`
  query($slug: String!) {
    allWpPost(filter: { slug: { eq: $slug } }) {
      nodes {
        title
        content
        featuredImage {
          node {
            sourceUrl
          }
        }
      }
    }
  }
`
